@import "../../../../utils/styles/fonts/_fonts.css";
@import "../../../../utils/styles/_variables.css";

.home__workers_section {
  width: 100%;
  height: 100%;
  padding: 0 0 60px 0;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.home__workers_wrapper {
  width: 100%;
}
.home__workers_box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 70px;
}
.home__workers_title {
  text-align: center;
  font-family: "Osnova-Pro";
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  color: var(--white);
  text-transform: uppercase;
}
.home__workers_primary {
  color: var(--primary);
}

@media (max-width: 1200px) {
  .home__workers_section {
    padding: 0 15px 60px 15px;
  }
  .home__workers_box {
    padding: 0 15px 0 15px;
  }
}

@media (max-width: 1000px) {
  .home__workers_title {
    font-size: 28px;
    line-height: 34px;
  }

  .home__workers_section {
    padding: 0 15px 60px 15px;
  }
  .home__workers_box {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .home__workers_section {
    padding: 0 15px 30px 15px;
  }

  .home__workers_title {
    font-size: 26px;
    line-height: 32px;
  }
}

@media (max-width: 600px) {
  .home__workers_box {
    gap: 30px;
  }
}

@media (max-width: 560px) {
  .home__workers_title {
    font-size: 20px;
    line-height: 26px;
  }
}
