@import "../../../../src/utils/styles/fonts/_fonts.css";
@import "../../../../src/utils/styles/_variables.css";

.client__recommendation_section {
  width: 100%;
  height: 100%;
  padding: 20px 0;
}
.client__main_section {
  width: 100%;
  height: 100%;
  background-image: url("../../../../public/images/about-made-bg.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: -100px;
  background-size: contain;
  padding: 20px 0 200px 0;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.client__recommendation_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.client__recommendation_route {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.client__router_icon:hover {
  cursor: pointer;
}
.client__router_icon:hover path {
  stroke: var(--primary);
}
.client__router_name {
  font-family: "Fixel-Display";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: var(--white);
  margin-top: 2px;
  cursor: pointer;
}
.client__router_name:hover {
  color: var(--primary);
}
.client__router_active {
  color: var(--primary);
}
.client__name_title {
  font-family: "Fixel-Display";
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  color: var(--primary);
}
.client__recommendation_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.client__info_title {
  font-family: "Fixel-Display";
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  color: var(--primary);
}
.client__info_list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 14px;
}
.client__list_item {
  list-style-type: disc;
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: var(--white);
}
.client__list_item::marker {
  width: 5px;
  height: 5px;
  color: var(--primary);
}
.client__wrapper_galery {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 180px 0 60px 0;
}
.client__galery_block {
  width: 800px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.client__galery_banners {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.client__galery_item {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: relative;
}
.client__banners_item {
  position: absolute;
  bottom: calc(100% + 20px);
  width: 140px;
}
.client__galery_count {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 22px;
}
.client__count_item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Fixel-Display";
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: var(--black);
  padding-top: 4px;
  padding-left: 1px;
}
.client__galery_line {
  width: 100%;
  height: 1px;
  background: var(--line-gray);
}
.client__info_text {
  width: 166px;
  list-style-type: disc;
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: var(--white);
  text-align: center;
  position: absolute;
  top: 50px;
}
.client__text_bold {
  font-weight: 500;
}
.client__wrapper_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.client__main_remember {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.client__main_title {
  font-family: "Fixel-Display";
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  color: var(--primary);
}
.client__list_item {
  list-style-type: disc;
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: var(--white);
}
.client__main_care {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.client__care_banners {
  display: flex;
  align-items: center;
  gap: 48px;
}
.client__main_save {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.client__save_block {
  display: flex;
  align-items: center;
  gap: 60px;
}

@media (max-width: 1200px) {
  .client__recommendation_section {
    padding: 20px 15px;
  }

  .client__main_section {
    padding: 20px 15px 200px 15px;
  }
}

@media (max-width: 1000px) {
  .client__galery_block {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .client__main_section {
    background-position-y: -20px;
  }

  .client__banners_item {
    width: 100px;
  }

  .client__info_text {
    font-size: 15px;
    line-height: 18px;
  }

  .client__text_bold {
    font-size: 15px;
  }
}

@media (max-width: 600px) {
  .client__recommendation_wrapper {
    gap: 50px;
  }

  .client__banners_item {
    max-width: 80px;
  }

  .client__recommendation_route {
    display: none;
  }

  .client__main_section {
    background-image: none;
  }

  .client__care_banners {
    gap: 0;
    justify-content: space-between;
  }

  .client__care_icon {
    width: 20%;
  }

  .client__name_title {
    font-size: 22px;
    line-height: 28px;
  }

  .client__save_icon {
    display: none;
  }

  .client__main_title,
  .client__info_title {
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
  }

  .client__info_text,
  .client__list_item {
    font-size: 15px;
  }

  .client__text_bold {
    font-size: 12px;
  }

  .client__banners_item {
    width: 72px;
  }

  .client__info_text {
    font-size: 12px;
    line-height: 15px;
    width: 112px;
  }

  .client__wrapper_galery {
    padding: 60px 0 60px 0;
  }
}

@media (max-width: 370px) {
  .client__banners_item {
    width: 56px;
  }

  .client__info_text {
    width: 80px;
  }

  .client__count_item {
    width: 18px;
    height: 18px;
    font-size: 14px;
    line-height: 16px;
    padding-left: 0;
    padding-top: 3px;
  }

  .client__wrapper_galery {
    padding: 60px 0 80px 0;
  }
}
