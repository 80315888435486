.catalog__main_characteristics {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.catalog__characteristics_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.catalog__info_text {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: var(--white);
}
.catalog__table_item {
  width: 100%;
  border-collapse: collapse;
}
.catalog__line_key,
.catalog__line_value {
  padding: 17px 42px;
  border: 1px solid var(--border-gray);
}
.catalog__line_key {
  width: 24%;
  text-align: left;
}
.catalog__line_key,
.catalog__line_value {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: var(--white);
}

@media (max-width: 600px) {
  .catalog__line_key,
  .catalog__line_value,
  .catalog__info_text {
    font-size: 15px;
    line-height: 20px;
  }

  .catalog__line_key {
    width: auto;
  }

  .catalog__line_key,
  .catalog__line_value {
    padding: 12px;
  }
}
