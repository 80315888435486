@import "../../../../../../src/utils/styles/fonts/_fonts.css";
@import "../../../../../../src/utils/styles/_variables.css";

.about__goal_section {
  width: 100%;
  height: 100%;
  background-image: url("../../../../../../public/images/about-goal-bg.png");
  background-repeat: no-repeat;
  background-position-x: 80px;
  background-size: contain;
  padding: 240px 0 150px 0;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.about__goal_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.about__wrapper_info {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.about__info_title {
  font-family: "Fixel-Display";
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  color: var(--primary);
  margin-bottom: 10px;
}
.about__title_upper {
  text-transform: uppercase;
}
.about__info_text {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: var(--white);
}
.about__wrapper_banner {
  width: 50%;
}
.about__banner_image {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

@media (max-width: 1200px) {
  .about__goal_section {
    padding: 200px 15px;
  }
}

@media (max-width: 1000px) {
  .about__goal_section {
    padding: 140px 15px;
    background-image: none;
  }

  .about__goal_wrapper {
    flex-direction: column;
  }

  .about__wrapper_info,
  .about__wrapper_banner {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .about__goal_section {
    padding: 100px 15px;
  }
}

@media (max-width: 600px) {
  .about__goal_section {
    padding: 70px 15px;
  }

  .about__goal_wrapper {
    gap: 15px;
  }

  .about__info_title {
    font-size: 22px;
    line-height: 28px;
  }

  .about__info_text {
    font-size: 15px;
  }

  .about__wrapper_info {
    gap: 15px;
  }
}
