@import "../../../../src/utils/styles/fonts/_fonts.css";
@import "../../../../src/utils/styles/_variables.css";

.about__policy_section {
  width: 100%;
  height: 100%;
  padding: 20px 0 30px 0;
}
.about__policy_first {
  background-image: url("../../../../public/images/about-made-bg.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
}
.about__section_second {
  padding: 0 0 240px 0;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.about__policy_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.about__policy_route {
  margin-bottom: 10;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.about__router_icon:hover {
  cursor: pointer;
}
.about__router_icon:hover path {
  stroke: var(--primary);
}
.about__router_name {
  font-family: "Fixel-Display";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: var(--white);
  margin-top: 2px;
  cursor: pointer;
}
.about__router_name:hover {
  color: var(--primary);
}
.about__router_active {
  color: var(--primary);
}
.about__name_title {
  font-family: "Fixel-Display";
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  color: var(--primary);
}
.about__main_list,
.about__policy_list {
  padding-left: 52px;
}
.about__policy_text {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: var(--white);
}
.about__primary_text {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: var(--primary);
}
.about__policy_main {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.about__main_title {
  font-family: "Fixel-Display";
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  color: var(--primary);
}
.about__main_block {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.about__primary_numeric {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: var(--primary);
  display: flex;
  gap: 8px;
  padding-left: 10px;
}
.about__primary_numeric span {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: var(--primary);
}
.about__warranty_list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 12px;
}
.about__list_item {
  list-style-type: disc;
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: var(--white);
}

.about__list_item::marker {
  height: 5px;
  width: 5px;
  color: var(--primary);
}

.about__list_numeric {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: var(--white);
  display: flex;
  gap: 10px;
}

@media (max-width: 1200px) {
  .about__policy_section {
    padding: 20px 15px 15px 15px;
  }

  .about__section_second {
    padding: 0 15px 200px 15px;
  }
}

@media (max-width: 1000px) {
  .about__section_second {
    padding: 0 15px 160px 15px;
  }
}

@media (max-width: 600px) {
  .about__policy_first {
    background-image: none;
  }

  .about__policy_route {
    display: none;
  }

  .about__policy_wrapper {
    gap: 30px;
  }

  .about__main_list,
  .about__primary_numeric {
    padding-left: 0;
  }

  .about__policy_list {
    padding-left: 40px;
  }

  .about__list_numeric,
  .about__list_item,
  .about__primary_numeric span,
  .about__primary_numeric,
  .about__policy_text,
  .about__primary_text {
    font-size: 15px;
  }

  .about__name_title,
  .about__main_title {
    font-size: 22px;
    line-height: 28px;
  }
}
