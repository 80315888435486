@import "../../../../src/utils/styles/fonts/_fonts.css";
@import "../../../../src/utils/styles/_variables.css";

.client__individual_section {
  width: 100%;
  height: 100%;
  background-image: url("../../../../public/images/about-made-bg.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
  padding: 20px 0 70px 0;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.client__individual_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 150px;
}
.client__individual_route {
  margin-bottom: 10;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.client__router_icon:hover {
  cursor: pointer;
}
.client__router_icon:hover path {
  stroke: var(--primary);
}
.client__router_name {
  font-family: "Fixel-Display";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: var(--white);
  margin-top: 2px;
  cursor: pointer;
}
.client__router_name:hover {
  color: var(--primary);
}
.client__router_active {
  color: var(--primary);
}
.client__individual_main {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.client__main_title {
  font-family: "Osnova-Pro";
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  color: var(--white);
  text-align: center;
  text-transform: uppercase;
}
.client__title_primary {
  color: var(--primary);
}
.client__main_insoles {
  width: 100%;
  display: grid;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 40px;
}
.client__main_insoles div {
  width: calc(22% - 40px);
}
.client__orthopedic_section {
  width: 100%;
  height: 100%;
  padding: 0 0 200px 0;
}
.client__orthopedic_wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.home__insole_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.home__item_image {
  height: 84px;
}
.home__item_text {
  max-width: 80%;
  text-align: center;
  font-family: "Fixel-Display";
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: var(--white);
}

@media (max-width: 1200px) {
  .client__individual_section {
    padding: 20px 15px 70px 15px;
  }

  .client__orthopedic_section {
    padding: 0 15px 200px 15px;
  }
}

@media (max-width: 1000px) {
  .client__main_insoles div {
    width: calc(33% - 40px);
  }
  .home__insole_item {
    width: 33%;
  }
}

@media (max-width: 900px) {
  .home__item_text {
    max-width: 80% !important;
  }
}

@media (max-width: 640px) {
  .client__main_insoles div {
    width: calc(50% - 40px);
  }

  .home__item_image {
    height: 66px;
  }

  .home__item_text {
    width: 100%;
    font-size: 15px;
  }
}

@media (max-width: 600px) {
  .client__individual_section {
    padding: 40px 15px 70px 15px;
  }

  .client__orthopedic_section {
    padding: 0 15px 140px 15px;
  }

  .client__individual_section {
    background-image: none;
  }

  .client__individual_route {
    display: none;
  }

  .client__main_title {
    font-size: 22px;
    line-height: 28px;
  }
  .home__insole_item {
    width: 50%;
  }

  .home__item_image {
    height: 66px;
  }

  .home__item_text {
    max-width: 100% !important;
    font-size: 15px;
  }
}
