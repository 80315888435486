@import "../../../../src/utils/styles/fonts/_fonts.css";
@import "../../../../src/utils/styles/_variables.css";

.about__contacts_section {
  width: 100%;
  height: 100%;
  background-image: url("../../../../public/images/about-contacts-bg.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: -60px;
  background-size: contain;
  padding: 20px 0 240px 0;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.about__contacts_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.about__contacts_route {
  margin-bottom: 10;
  display: flex;
  align-items: center;
  gap: 10px;
}
.about__router_icon:hover {
  cursor: pointer;
}
.about__router_icon:hover path {
  stroke: var(--primary);
}
.about__router_name {
  font-family: "Fixel-Display";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: var(--white);
  margin-top: 2px;
  cursor: pointer;
}
.about__router_name:hover {
  color: var(--primary);
}
.about__router_active {
  color: var(--primary);
}
.about__name_title {
  font-family: "Fixel-Display";
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  color: var(--primary);
}
.about__contacts_main {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.about__main_title {
  font-family: "Fixel-Display";
  font-size: 26px;
  font-weight: 400;
  line-height: 32px;
  color: var(--primary);
}
.about__title_upper {
  text-transform: uppercase;
  font-weight: 600;
}
.about__main_info {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.about__info_location {
  display: flex;
  align-items: center;
  gap: 20px;
}
.about__info_text {
  max-width: 300px;
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: var(--white);
}
.about__main_text {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: var(--white);
}
.about__text_primary {
  color: var(--primary);
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
}
.about__text_bold {
  font-weight: 600;
}
.about__main_payment {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1200px) {
  .about__contacts_section {
    padding: 20px 15px 200px 15px;
  }
}

@media (max-width: 1000px) {
  .about__contacts_section {
    padding: 20px 15px 160px 15px;
  }
}

@media (max-width: 600px) {
  .about__contacts_section {
    background-image: none;
  }
  .about__main_title,
  .about__title_upper,
  .about__name_title {
    font-size: 22px;
    line-height: 28px;
  }

  .about__contacts_route {
    display: none;
  }

  .about__info_text,
  .about__main_text {
    font-size: 15px;
  }
}
