.review__overlay {
  width: 100%;
  min-height: 100%;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}
.popup__review {
  width: 60%;
  background: var(--dark-gray);
  border-radius: 12px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 4;
  transform: translate(-50%, -50%);
}
.popup__review_wrapper {
  width: 100%;
  height: 100%;
  padding: 30px 72px;
  position: relative;
}
.popup__wrapper_close {
  position: absolute;
  top: 36px;
  right: 66px;
  cursor: pointer;
}
.popup__wrapper_title {
  text-align: center;
  font-family: "Fixel-Display";
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  color: var(--primary);
}
.popup__wrapper_rating {
  width: 100%;
  padding: 0 26px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
}
.popup__rating_text {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  color: var(--white);
}
.popup__rating_stars {
  display: flex;
  align-items: center;
  gap: 8px;
}
.popup__wrapper_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-top: 20px;
}
.popup__form_fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.popup__fields_control {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.popup__input_error {
  font-family: "Fixel-Display";
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  color: red;
}
.popup__fields_input {
  width: 100%;
  border: 1px solid var(--white);
  border-radius: 12px;
  padding: 15px 22px 12px 22px;
  background: transparent;
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  outline: none;
}
.popup__fields_input::placeholder {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
}
.popup__fields_textarea {
  width: 100%;
  border: 1px solid var(--white);
  border-radius: 12px;
  padding: 15px 22px;
  height: 64px;
  background: transparent;
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  outline: none;
  resize: none;
}
.popup__fields_textarea::placeholder {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
}
.popup__form_button {
  width: 400px;
  background: var(--primary);
  border-radius: 12px;
  border: 2px solid var(--primary);
  outline: none;
  cursor: pointer;
  padding: 16px 0;
  font-size: 20px;
  font-family: "Euclid";
  font-weight: 400;
  color: var(--dark);
  transition: 0.4s;
}
.popup__form_button:hover {
  background: transparent;
  color: var(--primary);
}
.popup__wrapper_info {
  margin-top: 50px;
  font-family: "Fixel-Display";
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}

@media (max-width: 1000px) {
  .popup__review {
    width: 72%;
  }
}

@media (max-width: 767px) {
  .popup__review {
    width: 80%;
  }

  .popup__review_wrapper {
    padding: 22px 20px 20px 20px;
  }

  .popup__wrapper_close {
    top: 30px;
    right: 20px;
  }

  .popup__wrapper_form {
    gap: 30px;
  }

  .popup__wrapper_info {
    margin-top: 30px;
  }

  .popup__form_button {
    width: 100%;
    padding: 10px 0;
  }
}

@media (max-width: 600px) {
  .popup__review {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }

  .popup__wrapper_title {
    text-align: left;
    font-size: 20px;
    line-height: 22px;
  }

  .popup__wrapper_rating {
    padding: 0;
    margin-top: 30px;
    gap: 15px;
  }

  .popup__wrapper_close {
    top: 26px;
    right: 20px;
  }

  .popup__fields_textarea {
    height: 80px;
  }
}
