@import "../../../../../utils/styles/fonts/_fonts.css";
@import "../../../../../utils/styles/_variables.css";

.home__worker_section {
  width: 100%;
  height: 100%;
  background-image: url("../../../../../../public/images/worker-bg.png");
  background-repeat: no-repeat;
  background-position: center left;
  background-size: contain;
  padding: 0 !important;
}
.home__worker_mobile {
  display: none;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.home__worker_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 60px;
}
.home__worker_section:nth-child(odd) {
  background-position: center left;
}
.home__worker_section:nth-child(even) {
  background-position: center right;
}
.home__worker_section:nth-child(odd) .home__worker_wrapper {
  flex-direction: row;
}
.home__worker_section:nth-child(even) .home__worker_wrapper {
  flex-direction: row-reverse;
}
.home__mobile_section:nth-child(odd) {
  background-position: center left;
}
.home__mobile_section:nth-child(even) {
  background-position: center right;
}
.home__mobile_section:nth-child(odd) .home__banners_mobile {
  flex-direction: row;
}
.home__mobile_section:nth-child(even) .home__banners_mobile {
  flex-direction: row-reverse;
}
.home__worker_banner {
  width: 50%;
  height: 568px;
  position: relative;
}
.home__worker_mask {
  width: 100%;
  height: 100%;
  top: 80px;
  left: -60px;
  z-index: -1;
  position: absolute;
  background-image: url("../../../../../../public/images/mask.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.home__worker_image {
  height: 100%;
  user-select: none;
}
.home__worker_info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 40px;
}
.home__info_header {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.home__info_title {
  font-family: "Fixel-Display";
  font-weight: 600;
  font-size: 34px;
  color: var(--primary);
  line-height: 40px;
}
.home__info_subtitle {
  font-family: "Fixel-Display";
  font-weight: 400;
  font-size: 22px;
  color: var(--white);
  line-height: 30px;
}
.home__info_about {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.home__about_text {
  font-family: "Fixel-Display";
  font-weight: 300;
  font-size: 18px;
  color: var(--white);
  line-height: 24px;
}
.home__info_galery {
  margin-top: 20px;
  position: relative;
}

.home__slider_card {
  position: relative;
  display: flex;
}

.home__slider_image {
  width: 100%;
  height: 120px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}

.home__galery_content {
  padding: 0 40px;
}

.home__galery_content > div {
  cursor: grab;
}

.arrow__slide_left {
  position: absolute;
  top: 50%;
  z-index: 1001;
  transform: translate(0, -50%);
  left: -6px;
  cursor: pointer;
}

.arrow__slide_right {
  position: absolute;
  top: 50%;
  z-index: 1001;
  transform: translate(0, -50%);
  right: -6px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .home__slider_image {
    width: 100%;
    max-height: 80px;
  }

  .home__galery_content div {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 1100px) {
  .home__worker_banner {
    height: 520px;
  }

  .home__worker_info {
    gap: 30px;
  }

  .home__info_about {
    gap: 14px;
  }

  .home__about_text {
    font-size: 16px;
  }
}

@media (max-width: 1000px) {
  .home__worker_mobile {
    width: 100%;
    height: 100%;
    padding: 0 15px;
    display: block;
  }

  .home__mobile_section {
    width: 100%;
    height: 100%;
    background-image: url("../../../../../../public/images/home-worker-mobile-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .home__galery_mobile {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .home__content_mobile {
    width: 200px;
    display: flex;
    align-items: flex-end;
    cursor: grab;
  }

  .arrow__slide_left {
    top: -28px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .arrow__slide_right {
    display: none;
  }

  .arrow__slide_left svg {
    transform: rotate(90deg) !important;
  }

  .home__worker_section {
    display: none;
    padding: 0 !important;
  }

  .home__banners_mobile {
    width: 100%;
    display: flex;
  }

  .home__galery_mobile {
    width: 50%;
  }

  .home__content_mobile > div {
    height: 500px;
  }

  .home__info_mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}

@media (max-width: 767px) {
  .home__mobile_section {
    height: 260px;
  }

  .arrow__slide_left {
    top: -42px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .home__banners_mobile {
    position: relative;
    bottom: 40px;
  }

  .home__content_mobile {
    height: 300px !important;
    width: 140px !important;
  }

  .home__content_mobile > div {
    height: 300px;
  }

  .home__content_mobile > div > div > div {
    height: 94px !important;
  }

  .home__worker_banner {
    height: 300px;
  }
}

@media (max-width: 600px) {
  .home__mobile_section {
    margin-top: 100px;
  }
  .home__about_text {
    font-size: 15px;
  }
  .home__info_subtitle {
    font-size: 15px;
    line-height: 20px;
  }
  .home__info_title {
    font-size: 20px;
    line-height: 26px;
  }
  .home__info_about {
    gap: 15px;
  }
  .home__worker_info,
  .home__info_mobile {
    gap: 20px;
  }

  .home__mobile_section {
    height: 212px;
  }

  .home__banners_mobile {
    position: relative;
    bottom: 68px;
  }

  .home__content_mobile {
    height: 230px !important;
    width: 100px !important;
  }

  .home__content_mobile > div {
    height: 230px;
  }

  .home__galery_mobile {
    display: flex;
    align-items: flex-end;
  }

  .home__content_mobile > div > div > div {
    height: 70px !important;
  }

  .home__worker_banner {
    height: 280px;
    display: flex;
    align-items: flex-end;
  }

  .home__worker_banner img {
    width: 100%;
    height: auto;
  }

  .arrow__slide_left {
    top: 10px;
  }

  .arrow__slide_left svg {
    width: 14px;
  }
}

@media (max-width: 500px) {
  .home__mobile_section {
    margin-top: 40px;
  }
}

@media (max-width: 440px) {
  .home__worker_banner {
    width: 60%;
  }

  .home__galery_mobile {
    width: 40%;
  }
}

@media (max-width: 370px) {
  .home__content_mobile {
    height: 200px !important;
    width: 90px !important;
  }

  .home__content_mobile > div {
    height: 200px;
  }
  .home__content_mobile > div > div > div {
    height: 60px !important;
  }

  .arrow__slide_left {
    top: 46px;
  }
}
