.home__insoles_section {
  width: 100%;
  height: 100%;
  padding: 0 0 270px 0;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.home__insoles_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 70px;
}

@media (max-width: 1200px) {
  .home__insoles_section {
    padding: 0 15px 240px 15px;
  }
}

@media (max-width: 1000px) {
  .home__insoles_section {
    padding: 0 15px 200px 15px;
  }
}

@media (max-width: 768px) {
  .home__insoles_section {
    padding: 0 15px 70px 15px;
  }
}
