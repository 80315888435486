.admin__section {
  width: 100%;
  height: 100%;
  background-image: url("../../../public/images/about-made-bg.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
  padding: 20px 0 100px 0;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.admin__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.admin__wrapper_route {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.admin__router_icon:hover {
  cursor: pointer;
}
.admin__router_icon:hover path {
  stroke: var(--primary);
}
.admin__router_name {
  font-family: "Fixel-Display";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: var(--white);
  margin-top: 2px;
  cursor: pointer;
}
.admin__router_name:hover {
  color: var(--primary);
}
.admin__router_active {
  color: var(--primary);
}
.admin__wrapper_inner {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}
.admin__inner_title {
  font-family: "Fixel-Display";
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  color: var(--white);
}

@media (max-width: 1200px) {
  .admin__section {
    padding: 20px 15px 100px 15px;
  }
}

@media (max-width: 600px) {
  .admin__section {
    background-image: none;
  }

  .admin__wrapper_route {
    display: none;
  }
}
