@import "../../../../utils/styles/fonts/_fonts.css";
@import "../../../../utils/styles/_variables.css";

.home__steps_section {
  width: 100%;
  height: 100%;
  padding: 0 0 270px 0;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.home__steps_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home__steps_title {
  text-align: center;
  font-family: "Osnova-Pro";
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  color: var(--white);
  text-transform: uppercase;
}
.home__steps_primary {
  color: var(--primary);
}
.home__steps_main {
  width: 100%;
  margin-top: 60px;
  display: grid;
  gap: 32px;
}
.home__steps_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--light-dark);
  border-radius: 10px;
}
.home__item_image {
  width: 100%;
  height: 260px;
}
.home__image_item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}
.home__item_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 18px 14px 14px 14px;
}
.home__info_title {
  font-family: "Osnova-Pro";
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--primary);
  text-transform: uppercase;
}
.home__info_text {
  max-width: 98%;
  text-align: center;
  font-family: "Fixel-Display";
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
}
.home__text_primary {
  color: var(--primary);
}
.home__text_icon {
  margin-left: 6px;
  margin-bottom: -6px;
}
.home__steps_first {
  grid-column-start: 1;
  grid-column-end: 2;
}
.home__steps_second {
  grid-column-start: 2;
  grid-column-end: 3;
}
.home__steps_third {
  grid-column-start: 3;
  grid-column-end: 4;
}
.home__steps_actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  margin-top: 40px;
}
.home__actions_button {
  background: transparent;
  width: 100%;
  border-radius: 12px;
  outline: none;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  font-family: "Euclid";
  font-weight: 400;
  font-size: 22px;
  transition: 0.4s;
}
.home__actions_order {
  box-shadow: 0 24px 46px rgba(240, 173, 0, 0.19);
  background: var(--primary);
  color: var(--black);
}

.home__actions_order:hover {
  background: transparent;
  border-color: var(--primary);
  color: var(--primary);
}

.home__actions_video {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: var(--primary);
  border: 1px solid var(--primary);
}

.home__actions_video:hover {
  background: var(--primary);
  border-color: var(--primary);
  color: var(--black);
}

@media (max-width: 1200px) {
  .home__steps_section {
    padding: 0 15px 200px 15px;
  }

  .home__info_text {
    width: 88%;
  }
}

@media (max-width: 1100px) {
  .home__steps_first {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .home__steps_second {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .home__steps_third {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}

@media (max-width: 1000px) {
  .home__steps_title {
    font-size: 28px;
    line-height: 34px;
  }
}

@media (max-width: 960px) {
  .home__info_text {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .home__actions_button {
    font-size: 18px;
  }

  .home__steps_title {
    font-size: 26px;
    line-height: 32px;
  }

  .home__steps_main {
    margin-top: 30px;
  }
}

@media (max-width: 680px) {
  .home__steps_first {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .home__steps_second {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .home__steps_item {
    flex-direction: row;
    align-items: center;
  }

  .home__item_info {
    align-items: flex-start;
  }

  .home__info_title,
  .home__info_text {
    text-align: left;
  }

  .home__actions_order {
    width: 100%;
  }

  .home__actions_video {
    display: none;
  }

  .home__item_image {
    width: 50%;
    height: 200px;
  }

  .home__item_info {
    width: 50%;
  }

  .home__image_item {
    border-radius: 10px;
  }

  .home__info_text {
    max-width: 80%;
  }
}

@media (max-width: 560px) {
  .home__steps_title {
    font-size: 20px;
    line-height: 26px;
  }

  .home__info_title {
    font-size: 16px;
  }

  .home__info_text {
    font-size: 15px;
  }
}

@media (max-width: 400px) {
  .home__info_text {
    max-width: 100%;
  }

  .home__item_info {
    padding: 10px 10px 10px 20px;
    gap: 4px;
  }
}

@media (max-width: 380px) {
  .home__item_image {
    height: 180px;
  }

  .home__info_text {
    line-height: 18px;
  }
}
