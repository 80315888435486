.catalog__main_item {
  width: calc(50% - 15px);
  padding: 25px;
  background: var(--dark-gray);
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 25px;
}
.catalog__item_banner {
  width: 50%;
  height: 100%;
}
.catalog__banner_image {
  width: 100%;
  max-height: 234px;
  border-radius: 15px;
}
.catalog__item_info {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.catalog__info_header {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.catalog__header_title {
  font-family: "Fixel-Display";
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  color: var(--white);
}
.catalog__info_reviews {
  display: flex;
  align-items: center;
  gap: 8px;
}
.catalog__reviews_stars {
  display: flex;
  align-items: center;
  gap: 6px;
}
.catalog__reviews_count {
  font-family: "Fixel-Display";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--white-gray);
  padding-top: 4px;
}
.catalog__header_left {
  display: flex;
}
.catalog__left_review {
  font-family: "Fixel-Display";
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: var(--primary);
}
.catalog__main_description {
  font-family: "Fixel-Display";
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: var(--white);
}
.catalog__info_footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.catalog__footer_price {
  font-family: "Fixel-Display";
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  color: var(--white);
}
.catalog__footer_button {
  background: var(--primary);
  border-radius: 8px;
  outline: none;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 7px 27px;
  font-family: "Euclid";
  font-weight: 400;
  font-size: 15px;
  color: var(--black);
  transition: 0.4s;
  box-shadow: 0 24px 46px rgba(240, 173, 0, 0.19);
  user-select: none;
  text-transform: uppercase;
}

.catalog__footer_button:hover {
  background: transparent;
  border-color: var(--primary);
  color: var(--primary);
}

@media (max-width: 1200px) {
  .catalog__header_title {
    font-size: 20px;
    line-height: 24px;
  }

  .catalog__footer_button {
    padding: 6px 22px;
  }

  .catalog__footer_price {
    font-size: 24px;
    line-height: 28px;
  }

  .catalog__main_item {
    padding: 20px;
    gap: 20px;
  }
}

@media (max-width: 1100px) {
  .catalog__header_title {
    font-size: 22px;
    line-height: 26px;
  }

  .catalog__main_item {
    width: 32%;
    padding: 15px;
    gap: 15px;
    flex-direction: column;
  }

  .catalog__item_banner,
  .catalog__item_info {
    width: 100%;
  }

  .catalog__footer_button {
    padding: 7px 27px;
  }

  .catalog__item_info {
    gap: 20px;
  }
}

@media (max-width: 900px) {
  .catalog__main_item {
    width: 49%;
    padding: 20px;
  }
  .catalog__banner_image {
    width: 100%;
    max-height: 260px;
    border-radius: 15px;
  }
}

@media (max-width: 700px) {
  .catalog__main_description {
    font-size: 15px;
  }

  .catalog__footer_price {
    font-size: 24px;
    line-height: 26px;
  }
}

@media (max-width: 600px) {
  .catalog__banner_image {
    height: 246px;
  }

  .catalog__main_item {
    width: 100%;
    padding: 15px;
  }
}
