.admin__login_section {
  width: 100%;
  height: 100vh;
  background-image: url("../../../../public/images/about-made-bg.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
  padding: 20px 0;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.admin__login_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.admin__wrapper_route {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.admin__router_icon:hover {
  cursor: pointer;
}
.admin__router_icon:hover path {
  stroke: var(--primary);
}
.admin__router_name {
  font-family: "Fixel-Display";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: var(--white);
  margin-top: 2px;
  cursor: pointer;
}
.admin__router_name:hover {
  color: var(--primary);
}
.admin__router_active {
  color: var(--primary);
}
.admin__wrapper_main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin__main_block {
  width: 60%;
  background: var(--dark-gray);
  border-radius: 12px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}
.admin__block_title {
  font-family: "Fixel-Display";
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  color: var(--white);
}
.admin__block_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.admin__form_field {
  width: 100%;
  border: 1px solid var(--white);
  border-radius: 12px;
  padding: 16px 26px 14px 26px;
  background: transparent;
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  outline: none;
}
.admin__form_field::placeholder {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
}
.admin__form_button {
  width: 100%;
  background: var(--primary);
  border-radius: 12px;
  border: 2px solid var(--primary);
  outline: none;
  cursor: pointer;
  padding: 16px 0;
  font-size: 20px;
  font-family: "Euclid";
  font-weight: 400;
  color: var(--dark);
  transition: 0.4s;
  box-shadow: 0 24px 46px rgba(240, 173, 0, 0.19);
  user-select: none;
  text-transform: uppercase;
}

.admin__form_button:hover {
  background: transparent;
  color: var(--primary);
}

@media (max-width: 1200px) {
  .admin__login_section {
    padding: 20px 15px;
  }
}

@media (max-width: 900px) {
  .admin__main_block {
    width: 100%;
    padding: 40px;
  }
}

@media (max-width: 600px) {
  .admin__login_section {
    background-image: none;
  }

  .admin__login_wrapper {
    height: 100vh;
  }

  .admin__wrapper_route {
    display: none;
  }

  .admin__block_title {
    font-size: 20px;
    line-height: 24px;
  }

  .admin__main_block {
    gap: 30px;
  }

  .admin__block_form {
    gap: 20px;
  }

  .admin__main_block {
    padding: 40px 20px;
  }
}
