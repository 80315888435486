@import "../../../../utils/styles/fonts/_fonts.css";
@import "../../../../utils/styles/_variables.css";

.home__order_section {
  width: 100%;
  height: 100%;
  padding: 0 0 270px 0;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.home__order_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 60px;
}
.home__order_title {
  text-align: center;
  font-family: "Osnova-Pro";
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  color: var(--white);
  text-transform: uppercase;
}
.home__order_primary {
  color: var(--primary);
}
.home__order_main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 80px;
}
.home__order_info {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.home__info_text {
  font-family: "Fixel-Display";
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
}
.home__text_primary {
  font-family: "Fixel-Display";
  font-weight: 400;
  font-size: 18px;
  color: var(--primary);
}
.home__info_galery {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 86px;
}
.home__order_video {
  width: 50%;
  min-height: 100%;
}
.home__video_link {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../../../../../public/images/order-video-preview.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 12px;
  position: relative;
}
.home__link_circle {
  position: absolute;
  height: 40px;
  width: 40px;
  background: var(--primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home__circle_play {
  margin-left: 3px;
  margin-top: 2px;
}

@media (max-width: 1200px) {
  .home__order_section {
    padding: 0 15px 240px 15px;
  }
}

@media (max-width: 1000px) {
  .home__order_section {
    padding: 0 15px 200px 15px;
  }

  .home__order_main {
    flex-direction: column;
  }

  .home__order_title {
    font-size: 28px;
  }

  .home__order_info,
  .home__order_video {
    width: 100%;
    height: 100%;
  }

  .home__video_link {
    height: 360px;
  }
}

@media (max-width: 768px) {
  .home__order_section {
    padding: 0 15px 120px 15px;
  }

  .home__order_title {
    font-size: 26px;
    line-height: 32px;
  }

  .home__order_wrapper {
    gap: 40px;
  }
}

@media (max-width: 600px) {
  .home__order_section {
    padding: 0 15px 70px 15px;
  }

  .home__info_galery {
    display: none;
  }

  .home__info_text {
    font-size: 15px;
  }

  .home__order_wrapper {
    gap: 20px;
  }

  .home__order_main {
    gap: 40px;
  }

  .home__video_link {
    height: 300px;
  }
}

@media (max-width: 560px) {
  .home__order_title {
    font-size: 20px;
    line-height: 26px;
  }
}
