.admin__error_section {
  width: 100%;
  height: 100vh;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.admin__error_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin__wrapper_info {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.admin__info_title {
  font-family: "Fixel-Display";
  font-size: 32px;
  font-weight: 600;
  line-height: 34px;
  color: var(--primary);
}
.admin__info_text {
  font-family: "Fixel-Display";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--white);
}
.admin__info_button {
  width: 260px;
  background: var(--primary);
  border-radius: 12px;
  border: 2px solid var(--primary);
  outline: none;
  cursor: pointer;
  padding: 12px 0;
  font-size: 16px;
  font-family: "Euclid";
  font-weight: 400;
  color: var(--dark);
  transition: 0.4s;
  box-shadow: 0 24px 46px rgba(240, 173, 0, 0.19);
  user-select: none;
}
.admin__info_button:hover {
  background: transparent;
  color: var(--primary);
}
.admin__wrapper_banner {
  width: 40%;
}
.admin__wrapper_banner img {
  width: 100%;
}

@media (max-width: 768px) {
  .admin__error_section {
    padding: 100px 15px;
  }

  .admin__error_wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
  }

  .admin__wrapper_info {
    width: 100%;
  }

  .admin__wrapper_banner {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .admin__info_title {
    font-size: 26px;
    line-height: 30px;
  }
}
