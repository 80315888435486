@import "../../../../src/utils/styles/fonts/_fonts.css";
@import "../../../../src/utils/styles/_variables.css";

.main {
  width: 100%;
}
.about__prostopoo_section {
  width: 100%;
  height: 100%;
  padding: 20px 0;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.about__prostopoo_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 70px;
}
.about__wrapper_router {
  display: flex;
  align-items: center;
  gap: 10px;
}
.about__router_icon:hover {
  cursor: pointer;
}
.about__router_icon:hover path {
  stroke: var(--primary);
}
.about__router_name {
  font-family: "Fixel-Display";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: var(--primary);
  margin-top: 4px;
  cursor: pointer;
}
.about__router_name:hover {
  color: var(--primary);
}
.about__wrapper_title {
  font-family: "Fixel-Display";
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  color: var(--primary);
}

.about__prostopoo_footer {
  width: 100%;
  height: 100%;
  padding: 0 0 240px 0;
}
.about__footer_wrapper {
  width: 100%;
}
.about__footer_title {
  text-align: center;
  font-family: "Fixel-Display";
  font-size: 44px;
  font-weight: 600;
  line-height: 50px;
  color: var(--white);
}
.about__title_primary {
  color: var(--primary);
}

@media (max-width: 1200px) {
  .about__prostopoo_section {
    padding: 20px 15px;
  }

  .about__prostopoo_footer {
    padding: 0 15px 200px 15px;
  }
}

@media (max-width: 768px) {
  .about__prostopoo_footer {
    padding: 0 15px 160px 15px;
  }

  .about__footer_title {
    font-size: 34px;
  }
}

@media (max-width: 600px) {
  .about__wrapper_title {
    font-size: 22px;
    line-height: 28px;
  }

  .about__wrapper_router {
    display: none;
  }

  .about__wrapper_title {
    display: none;
  }

  .about__footer_title {
    font-size: 28px;
  }
}
