.cart__section {
  width: 100%;
  height: 100%;
  background-image: url("../../../../public/images/about-made-bg.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
  padding: 20px 0 60px 0;
  margin-bottom: 200px;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.cart__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 68px;
}
.cart__made_route {
  margin-bottom: 10;
  display: flex;
  align-items: center;
  gap: 10px;
}
.cart__router_icon:hover {
  cursor: pointer;
}
.cart__router_icon:hover path {
  stroke: var(--primary);
}
.cart__router_name {
  font-family: "Fixel-Display";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: var(--white);
  margin-top: 2px;
  cursor: pointer;
}
.cart__router_name:hover {
  color: var(--primary);
}
.cart__router_active {
  color: var(--primary);
}
.cart__wrapper_inner {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0 12px;
}
.cart__inner_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: relative;
}
.cart__block_circle {
  width: 38px;
  height: 38px;
  background: var(--border-gray);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart__block_active > .cart__block_circle {
  background: var(--primary);
}
.cart__inner_line {
  width: 120px;
  height: 1px;
  background: var(--border-gray);
}
.cart__block_text {
  width: 132px;
  font-family: "Fixel-Display";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--border-gray);
  text-align: center;
  position: absolute;
  top: 48px;
}
.cart__block_active > .cart__block_text {
  color: var(--primary);
}
.cart__wrapper_main {
  width: 100%;
  display: flex;
  gap: 30px;
  margin-top: 50px;
}

@media (max-width: 1200px) {
  .cart__section {
    padding: 20px 15px 60px 15px;
  }
}

@media (max-width: 900px) {
  .cart__wrapper_main {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .cart__section {
    margin-bottom: 140px;
    background-image: none;
  }

  .cart__made_route {
    display: none;
  }

  .cart__wrapper_inner {
    width: 100%;
    justify-content: space-between;
    gap: 12px;
    padding: 0 36px 0 18px;
  }

  .cart__block_circle {
    width: 32px;
    height: 32px;
  }

  .cart__circle_icon {
    height: 16px;
  }

  .cart__block_text {
    width: 120px;
    font-size: 11px;
    line-height: 14px;
    top: 40px;
  }

  .cart__wrapper_main {
    gap: 20px;
    margin-top: 0;
  }

  .cart__inner_line {
    width: 100%;
  }
}
