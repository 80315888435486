@import "../../../../src/utils/styles/fonts/_fonts.css";
@import "../../../../src/utils/styles/_variables.css";

.catalog__orthopedic_section {
  width: 100%;
  height: 100%;
  background-image: url("../../../../public/images/catalog-orthopedic-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  padding: 20px 0;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.catalog__orthopedic_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.catalog__wrapper_route {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.catalog__router_icon:hover {
  cursor: pointer;
}
.catalog__router_icon:hover path {
  stroke: var(--primary);
}
.catalog__router_name {
  font-family: "Fixel-Display";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: var(--white);
  margin-top: 2px;
  cursor: pointer;
}
.catalog__router_name:hover {
  color: var(--primary);
}
.catalog__router_active {
  color: var(--primary);
}
.catalog__wrapper_main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  padding-bottom: 80px;
}
.admin__products_empty {
  width: 100%;
  text-align: center;
  font-family: "Fixel-Display";
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  color: var(--white);
}

@media (max-width: 1200px) {
  .catalog__orthopedic_section {
    padding: 20px 15px;
  }
}

@media (max-width: 1100px) {
  .catalog__wrapper_main {
    width: 100%;
    gap: 2%;
    row-gap: 16px;
    padding-bottom: 60px;
  }
}

@media (max-width: 900px) {
  .catalog__orthopedic_section {
    background-image: none;
  }
}

@media (max-width: 600px) {
  .catalog__wrapper_route {
    display: none;
  }

  .catalog__wrapper_main {
    flex-direction: column;
    gap: 30px;
  }
}
