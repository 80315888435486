@import "../../utils/styles/fonts/_fonts.css";
@import "../../utils/styles/_variables.css";

.footer__section {
  width: 100%;
  height: 100%;
  background: var(--black);
}

.footer__container {
  max-width: 1280px;
  margin: 0 auto;
}

.footer__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 112px 0;
  border-top: 1px solid var(--border-gray);
}

.footer__wrapper_info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer__info_logo {
  width: 220px;
  cursor: pointer;
}

.footer__logo_item {
  width: 100%;
}

.footer__info_socials {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.footer__socials_item {
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: 0.5s;
}

.footer__socials_item:hover {
  transform: scale(1.1);
}

.footer__socials_icon {
  width: 100%;
  height: 100%;
}

.footer__work_text {
  font-family: "Fixel-Display";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
}

.footer__work_primary {
  font-family: "Fixel-Display";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary);
}

.footer__info_payment {
  display: flex;
  align-items: center;
  gap: 12px;
}

.footer__payment_item {
  height: 32px;
  cursor: pointer;
}

.footer__payment_icon {
  width: 100%;
  height: 100%;
}

.footer__wrapper_menu {
  display: flex;
  gap: 100px;
  justify-content: space-between;
}

.footer__menu_item {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer__menu_title {
  font-family: "Fixel-Display";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary);
}

.footer__menu_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer__link_item {
  font-family: "Fixel-Display";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgb(255, 255, 255, 0.7);
  transition: 0.4s;
}

.footer__link_item:hover {
  color: var(--primary);
}

@media (max-width: 1100px) {
  .footer__wrapper {
    row-gap: 60px;
    padding: 92px 0;
  }

  .footer__wrapper_info,
  .footer__menu_item {
    width: 48%;
  }
}

@media (max-width: 800px) {
  .footer__wrapper_logo {
    width: 180px;
  }

  .footer__menu_list {
    gap: 12px;
  }

  .footer__menu_item {
    gap: 16px;
  }

  .footer__wrapper {
    flex-direction: column;
    row-gap: 40px;
    padding: 60px 0 40px 0;
  }

  .footer__wrapper_info,
  .footer__menu_item {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .footer__wrapper_logo {
    width: 162px;
  }

  .footer__wrapper {
    flex-direction: column;
    row-gap: 40px;
    padding: 40px 0 20px 0;
  }
}
