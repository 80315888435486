@import "../../../../utils/styles/fonts/_fonts.css";
@import "../../../../utils/styles/_variables.css";

.home__made_section {
  width: 100%;
  height: 100%;
  background-image: url("../../../../../public/images/home-made-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 100px 0 160px 0;
}

.home__made_container {
  max-width: 1280px;
  margin: 0 auto;
}

.home__made_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home__made_info {
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.home__info_title {
  font-family: "Osnova-Pro";
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  color: var(--primary);
}

.home__title_small {
  font-family: "Osnova-Pro";
  font-weight: 700;
  font-size: 34px;
  color: var(--white);
}

.home__info_free {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.home__free_title {
  font-family: "Fixel-Display";
  font-weight: 600;
  font-size: 18px;
  color: var(--white);
  text-transform: uppercase;
}

.home__free_block {
  display: flex;
  align-items: center;
  gap: 14px;
}

.home__free_item {
  padding: 8px;
  border: 1px solid var(--primary);
  border-radius: 5px;
  font-family: "Fixel-Display";
  font-weight: 400;
  font-size: 18px;
  color: var(--white);
}

.home__free_delivery {
  padding: 8px;
  letter-spacing: 0.8px;
  border: 1px solid var(--primary);
  border-radius: 5px;
  font-family: "Fixel-Display";
  font-weight: 400;
  font-size: 18px;
  color: var(--white);
}

.home__info_button {
  box-shadow: 0 24px 46px rgba(240, 173, 0, 0.19);
  background: var(--primary);
  max-width: 500px;
  border-radius: 12px;
  outline: none;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  font-family: "Euclid";
  font-weight: 400;
  font-size: 26px;
  color: var(--black);
  transition: 0.4s;
}

.home__info_button:hover {
  background: transparent;
  border-color: var(--primary);
  color: var(--primary);
}

.home__mobile_button {
  display: none;
  box-shadow: 0 24px 46px rgba(240, 173, 0, 0.19);
  background: var(--primary);
  width: 100%;
  border-radius: 12px;
  outline: none;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 16px 0;
  font-family: "Euclid";
  font-weight: 400;
  font-size: 20px;
  color: var(--black);
  transition: 0.4s;
}

.home__mobile_button:hover {
  background: transparent;
  border-color: var(--primary);
  color: var(--primary);
}

.home__insoles_banner {
  height: 460px;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch;
  background: none;
  display: block;
}

@media (max-width: 1280px) {
  .home__made_section {
    padding: 100px 15px 200px 15px;
  }
}

@media (max-width: 1200px) {
  .home__info_title {
    font-size: 38px;
    line-height: 44px;
  }

  .home__title_small {
    line-height: 36px;
    font-size: 30px;
  }

  .home__info_button {
    font-size: 22px;
  }
}

@media (max-width: 1100px) {
  .home__info_title {
    font-size: 36px;
    line-height: 40px;
  }

  .home__insoles_banner {
    height: 340px;
  }
}

@media (max-width: 1000px) {
  .home__made_wrapper {
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }

  .home__made_section {
    padding: 100px 15px 140px 15px;
  }

  .home__info_title {
    text-align: center;
  }

  .home__free_title {
    text-align: center;
  }

  .home__insoles_banner {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .home__made_section {
    padding: 40px 15px 70px 15px;
    background-image: none;
  }

  .home__insoles_banner {
    width: 100%;
    height: auto;
  }

  .home__insoles_banner {
    width: 100%;
  }

  .home__info_title {
    text-align: center;
    font-size: 42px;
  }

  .home__info_button {
    display: none;
  }

  .home__mobile_button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home__made_insoles {
    width: calc(100% + 30px);
    background-image: url("../../../../../public/images/home-made-bg.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 20px 0;
  }

  .home__info_free {
    align-items: center;
  }

  .home__free_item,
  .home__free_delivery {
    font-size: 14px;
  }
}

@media (max-width: 416px) {
  .home__info_title {
    font-size: 36px;
  }

  .home__free_block {
    flex-direction: column;
    align-items: flex-start;
  }

  .home__info_free {
    align-items: flex-start;
  }
}

@media (max-width: 350px) {
  .home__info_title {
    font-size: 32px;
  }
}