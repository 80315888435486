@import "./utils/styles/_base.css";

.disable {
  background: gray !important;
  border: 1px solid gray !important;
  color: gray !important;
}

.disable:hover {
  background: gray !important;
  border: 1px solid gray !important;
  color: black !important;
}

.worker-slider .swiper-slide div {
  height: 100% !important;
  width: 100% !important;
}

.worker-slider .swiper-slide div img {
  height: 100%;
  width: 100%;
}
