:root {
  --primary: #ffed00;
  --white: #ffffff;
  --black: #1d1d1b;
  --dark-gray: #292929;
  --border-light-gray: #93939380;
  --border-gray: #939393;
  --white-gray: #ffffff80;
  --light-dark: #2e2e2c;
  --size-dark: #20201f;
  --line-gray: #515150;
}
