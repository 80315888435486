.cart__main_forms {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.cart__forms_block {
  width: 50%;
  background: var(--dark-gray);
  border-radius: 12px;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.cart__forms_title {
  padding-left: 26px;
  font-family: "Fixel-Display";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--white);
}
.cart__form_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.cart__item_control {
  position: relative;
}
.cart__control_star {
  position: absolute;
  top: 12px;
  left: 16px;
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: var(--primary);
}
.cart__forms_field {
  width: 100%;
  border: 1px solid var(--white);
  border-radius: 12px;
  padding: 16px 26px 14px 26px;
  background: transparent;
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  outline: none;
}
.cart__forms_field::placeholder {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
}
.cart__item_textarea {
  width: 100%;
  height: 52px;
  border: 1px solid var(--white);
  border-radius: 12px;
  padding: 15px 26px;
  background: transparent;
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  outline: none;
  resize: none;
}
.cart__item_textarea::placeholder {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
}
.cart__wrapper_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 50px;
  margin-top: 50px;
}
.cart__main_data {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cart__main_footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart__data_text {
  display: none;
}
.cart__required_text {
  font-family: "Fixel-Display";
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
}
.cart__text_primary {
  color: var(--primary);
}
.cart__footer_continue {
  display: flex;
  align-items: center;
  gap: 72px;
}
.cart__continue_block {
  display: flex;
  align-items: center;
  gap: 15px;
}
.cart__continue_text {
  font-family: "Fixel-Display";
  font-size: 25px;
  font-weight: 400;
  line-height: 26px;
  color: var(--border-gray);
}
.cart__continue_price {
  font-family: "Fixel-Display";
  font-size: 25px;
  font-weight: 600;
  line-height: 26px;
  color: var(--white);
}
.cart__continue_actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cart__continue_button {
  width: 320px;
  background: var(--primary);
  border-radius: 12px;
  border: 2px solid var(--primary);
  outline: none;
  cursor: pointer;
  padding: 16px 0;
  font-size: 20px;
  font-family: "Euclid";
  font-weight: 400;
  color: var(--dark);
  transition: 0.4s;
  box-shadow: 0 24px 46px rgba(240, 173, 0, 0.19);
  user-select: none;
  text-transform: uppercase;
}

.cart__continue_button:hover {
  background: transparent;
  color: var(--primary);
}

.cart__forms_control {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cart__actions_error {
  font-size: 16px;
  color: #eb001b;
  font-family: "Fixel-Display";
  font-weight: 300;
  line-height: 16px;
}

.error {
  font-size: 13px;
  color: #eb001b;
  font-family: "Fixel-Display";
  font-weight: 300;
  line-height: 16px;
  margin-top: 8px;
  display: block;
}

@media (max-width: 1100px) {
  .cart__required_text {
    max-width: 200px;
  }

  .cart__continue_price,
  .cart__continue_text {
    font-size: 24px;
  }

  .cart__footer_continue {
    gap: 60px;
  }
}

@media (max-width: 1000px) {
  .cart__required_text {
    display: none;
  }

  .cart__data_text {
    display: block;
    font-family: "Fixel-Display";
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.5);
  }
}

@media (max-width: 768px) {
  .cart__main_forms {
    flex-direction: column;
  }

  .cart__forms_block {
    width: 100%;
    padding: 25px 15px;
  }

  .cart__footer_continue {
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;
  }
}

@media (max-width: 600px) {
  .cart__forms_field,
  .cart__forms_field::placeholder,
  .cart__item_textarea,
  .cart__item_textarea::placeholder,
  .cart__control_star {
    font-size: 15px;
  }

  .cart__control_star {
    top: 14px;
  }

  .cart__forms_title {
    font-size: 18px;
    line-height: 22px;
  }

  .cart__forms_block {
    gap: 26px;
  }

  .cart__form_item {
    gap: 25px;
  }

  .cart__footer_continue {
    width: 100%;
    gap: 30px;
  }

  .cart__continue_button {
    padding: 14px 0;
  }

  .cart__wrapper_main {
    gap: 30px;
    margin-top: 0;
  }

  .cart__inner_line {
    width: 100%;
  }

  .cart__continue_block {
    gap: 6px;
  }

  .cart__continue_text {
    font-size: 15px;
    line-height: 20px;
  }

  .cart__continue_price {
    font-size: 22px;
    line-height: 26px;
  }
}

@media (max-width: 460px) {
  .cart__continue_actions {
    width: 100%;
  }
  .cart__continue_button {
    width: 100%;
  }
}
